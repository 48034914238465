import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { throttle } from "../../utils";
import { NavItem } from "./NavItem";

import "./NavBar.scss";
import CartIcon from '../../images/shopping-cart.svg';
import { Cart } from "../cart/Cart";
import { useContext } from "react";
import { CartContext } from "../providers/Cart";

const NavBar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    const throttledScroll = throttle(onScroll, 100);
    window.addEventListener("scroll", throttledScroll);

    return () => window.removeEventListener("scroll", throttledScroll);
  }, [scrollTop]);

  const [showNavOnMobile, setShowNavOnMobile] = useState(false);
  const [showCart, setShowCart] = useState(false);

  const {
    cartState,
  } = useContext(CartContext);

  return (
    <>
    <nav
      className={`navbar navbar-light navbar-expand-lg main-navigation ${scrollTop > 0 ? "scrolling" : ""}`}
    >
      <div className="container">
        <Link to="/" className="navbar-brand">Project Wool</Link>
        <div className="order-md-last">
          <button className='btn position-relative p-0' onClick={() => setShowCart(!showCart)}>
            <CartIcon />
            { cartState && cartState.totalItems > 0 ?
              <span className="cart-items__counter position-absolute start-100 translate-middle badge rounded-pill bg-danger">
                { cartState.totalItems }
                <span className="visually-hidden">items in cart</span>
              </span>
              :
              null
            }

          </button>
          <button
            className="navbar-toggler"
            onClick={() => setShowNavOnMobile(!showNavOnMobile)}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className={`collapse navbar-collapse ${showNavOnMobile ? 'show' : ''}`} id="navbarNavDropdown">
          <ul className="navbar-nav">
            <NavItem to={"/"} text={"Home"} />
            <NavItem to={"/shop/"} text={"Shop"} />
            <NavItem to={"/about/"} text={"About"} />
          </ul>
        </div>
      </div>
    </nav>
    <Cart cartIsOpen={showCart} onCartClose={() => setShowCart(false)} />
    </>
  );
};

export default NavBar;
