import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { CartContext } from "../providers/Cart";
import { CartActions } from "../providers/Cart/state";
import { ProductsContext } from "../providers/Products";

import axios from "axios";
import "./Cart.scss";

export const Cart = (props: CartProps) => {
  const { dispatchCartActions, cartState } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const { products, prices } = useContext(ProductsContext);

  const [checkoutUrl, setCheckoutUrl] = useState("");
  useEffect(() => {
    if (process.env.GATSBY_ENV === "prod") {
      setCheckoutUrl("https://7n8ewu8gsf.execute-api.us-east-1.amazonaws.com/prod/checkout");
    } else {
      setCheckoutUrl(
        "https://7n8ewu8gsf.execute-api.us-east-1.amazonaws.com/qa/checkout"
      );
    }
  }, []);

  async function handleSubmit() {
    if (!loading) {
      setLoading(true);

      try {
        const lineItems = cartState.itemIds.map((id, index) => {
          return {
            price: id,
            quantity: cartState.items[id],
          };
        });
        const rs = await axios.post(
          checkoutUrl,
          { lineItems },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        window.location = rs.data.session;
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${
          props.cartIsOpen ? "cart show cart__visible" : "cart"
        }`}
        tabIndex={-1}
        id="offCanvasCart"
        aria-labelledby="offCanvasCartLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offCanvasCartLabel">
            Review Your Cart
          </h5>
          <button
            onClick={props.onCartClose}
            type="button"
            className="btn-close text-reset"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {cartState && Object.keys(cartState.items).length > 0 ? (
            <>
              <div className="d-flex flex-column">
                {cartState && cartState.itemIds &&
                  cartState.itemIds.length &&
                  cartState.itemIds.map((id, index) => {
                    const price = prices[id];
                    const product = products[id];
                    return (
                      <div className="d-flex mb-3" key={id}>
                        <div>
                          <GatsbyImage
                            alt={product.productName}
                            image={getImage(product.images[0])}
                          />
                        </div>
                        <div className="d-flex flex-column ms-3">
                          <div>{product.productName}</div>
                          <div>
                            {cartState.items[id]} x ${price.unit_amount / 100}{" "}
                            each
                          </div>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              dispatchCartActions({
                                type: CartActions.CLEAR_ITEM_FROM_CART,
                                payload: { productId: id },
                              })
                            }
                            type="button"
                            className="btn btn-link"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="d-flex flex-direction-column">
                <button onClick={handleSubmit} className="btn btn-primary">
                  {loading ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    <>Proceed To Checkout</>
                  )}
                </button>
              </div>
            </>
          ) : (
            <div>Your cart is empty</div>
          )}
        </div>
      </div>
      {props.cartIsOpen && (
        <div className={`offcanvas-backdrop fade show visible'`}></div>
      )}
    </>
  );
};

interface CartProps {
  onCartClose: (event: React.SyntheticEvent) => void;
  cartIsOpen: boolean;
}
