import { Link } from "gatsby";
import React from "react";

export const NavItem = ({ to, text }: { to: string; text: string }) => {
  return (
    <li className="nav-item">
      <Link className="nav-link" to={to}>
        {text}
      </Link>
    </li>
  );
};

export interface NavItemModel {
  to: string;
  text: string;
}
