import React from "react";
import './Footer.scss';

import Instagram from '../../images/instagram.svg';
import Facebook from '../../images/facebook.svg';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="footer-content">
              <a className="btn link" href="https://www.facebook.com/Project-Wool-1968115423495363">
                <Facebook />
              </a>
              <a className="btn link" href="https://www.instagram.com/projectwoolmats">
                <Instagram />
              </a>
            </div>
          </div>
          <div className="col footer-content">
            <h6>Contact</h6>
            <div>
              <address>
                <div className="block">
                  <a href="mailto:dorothy@projectwool.com">
                    dorothy@projectwool.com
                  </a>
                </div>
              </address>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
